import { createSchema } from 'morphism';
import { LogoProps } from 'libs/growth-platform-brand-system-v2/src/components/Logo';
import { PixelDensity } from 'libs/growth-platform-brand-system-v2/src/components/Image/types';

export const schemaLogo = createSchema<LogoProps, any>({
  loading: {
    path: '',
    fn: () => 'eager',
  },
  isEncapsulated: 'isEncapsulated',
  isAnimated: 'isAnimated',
  alt: {
    path: 'alt',
    fn: (propertyValue: string, source: any) => {
      return propertyValue || source?.altDefault || '';
    },
  },
  width: 'width',
  height: 'height',
  src: 'url',
  pixelDensity: {
    path: 'pixelDensity',
    fn: (propertyValue: PixelDensity) => {
      return propertyValue || 'image';
    },
  },
  sizes: {
    path: '',
    fn: () => ['180px'],
  },
});

export const schemaLogoList = createSchema<
  LogoProps & {
    isEncapsulated?: boolean;
    isAnimated?: boolean;
    className?: string;
  },
  any
>({
  ...schemaLogo,
  className: 'className',
});
