import { useScreenSize } from 'libs/growth-platform-brand-system-v2/src/utils/utils';
import { LogosList } from 'libs/growth-platform-brand-system-v2/src/layouts/LogosList';

import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../../layout';
import { Query } from '../../../types/dato';
import { PageProps } from 'gatsby';
import { HeadWrapper } from '../../layout/HeadWrapper';
import { morphism } from 'morphism';
import { schemaHero } from '../../morphismSchema/templates/schemaHero';
import { Hero } from 'libs/growth-platform-brand-system-v2/src/templates/Hero';
import { schemaLogo } from '../../morphismSchema/components/schemaLogoList';
import { TemplateBlocksZone } from '../../components/TemplateBlocksZone';
import { schemaBaseTemplate } from '../../morphismSchema/pages/schemaBaseTemplate';

export const Head = ({ data, pageContext, location }: any) => {
  return (
    <HeadWrapper
      data={data}
      pageContext={pageContext}
      location={location}
      templateName="ServicePage"
      dataName={'datoCmsServicePage'}
    />
  );
};
export const query = graphql`
  query DatoServicePage($locale: String, $id: String) {
    datoCmsServicePage(locale: $locale, id: { eq: $id }) {
      ...ServicePage
    }
  }
`;

const ServicePage = ({ data, pageContext }: PageProps<Query>) => {
  const doc: any = data.datoCmsServicePage;
  const screenSize = useScreenSize();
  const { heroLogos, ..._data } = morphism(schemaBaseTemplate, {
    ...doc,
    screenSize,
    pageContext,
  });

  if (!doc) return null;

  return (
    <Layout pageContext={pageContext} data={doc} templateName="ServicePage">
      <Hero
        {...morphism(schemaHero, {
          ..._data?.hero,
        })}
      >
        {heroLogos?.length > 0 && (
          <LogosList
            isAnimated
            isEncapsulated
            logos={morphism(schemaLogo, heroLogos) as any}
            title={
              _data?.hero?.heroAdditionalComponent?.logosListTitle || undefined
            }
            className="mt-64 md:mt-104"
          />
        )}
      </Hero>

      {_data?.blocks.length > 0 && <TemplateBlocksZone {..._data} />}
    </Layout>
  );
};

export default ServicePage;
